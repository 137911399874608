import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationResponse } from '../models/authentication/authentication-response';
import { AuthenticationRequest } from '../models/authentication/authentication-request';
import { RefreshTokenResponse } from '../models/authentication/refresh-token-response';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': environment.centerServer.apiKey
    })
}

const apiUrlLogin = environment.centerServer.domain + '/customer/login'
const apiUrlRefreshToken = environment.centerServer.domain + '/customer/refresh'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    constructor(private readonly http: HttpClient) { }

    login(authenticationRequest: AuthenticationRequest): Observable<AuthenticationResponse> {
        return this.http.post<AuthenticationResponse>(apiUrlLogin, authenticationRequest, httpOptions);
    }

    getAuthStatus(): boolean {
        return (sessionStorage.getItem('access_token') && sessionStorage.getItem('refresh_token')) ? true : false
    }

    refreshToken(): Observable<any> {
        const refresh_token = sessionStorage.getItem('refresh_token')!;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-api-key': environment.centerServer.apiKey,
                'Authorization': refresh_token
            })
        }
        return this.http.post<RefreshTokenResponse>(apiUrlRefreshToken, {}, httpOptions)
    }
}
