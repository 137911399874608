import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, throwError, switchMap, retry } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService,
        private router: Router) { }

    handleResponseError(error: any, request?: any, next?: any): any {
        if (error.status === 401) {
            return this.authenticationService.refreshToken().pipe(
                switchMap((result) => {
                    if (result && result.statusCode == 1000) {
                        sessionStorage.setItem('access_token', 'Bearer ' + result.access_token);
                        var newHeader = new HttpHeaders({
                            'Content-Type': 'application/json',
                            'x-api-key': environment.centerServer.apiKey,
                            'Authorization': 'Bearer ' + result.access_token
                        });
                        if (request.headers.headers.size == 2) {
                            newHeader = new HttpHeaders({
                                'x-api-key': environment.centerServer.apiKey,
                                'Authorization': 'Bearer ' + result.access_token
                            });
                        }
                        var req = request.clone({
                            headers: newHeader
                        })
                        return next.handle(req);
                    } else {
                        this.router.navigateByUrl("/authentication/signin");
                    }
                }),
                catchError(e => {
                    if (e.status !== 401) {
                        return this.handleResponseError(e);
                    } else {
                        this.router.navigateByUrl("/authentication/signin");
                    }
                }));
        } else if (error.status === 403) { // Access denied error
            this.router.navigateByUrl("/authentication/signin");
        }
        return throwError(() => new Error(error));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            catchError((error) => {
                return this.handleResponseError(error, req, next).pipe(retry(2));
            })
        )
    }
}
